// handlePusherAuthenticated.js
'use strict';
import setAbTestConfig from '../action/setABTestConfig.js';
import mergeMeData from '../action/mergeMeData.js';
import { setRemoteMeta } from '../resource/mixpanel.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import setConfigurations from '../action/setConfigurations.js';
import connectCustomerServiceWithUserId from './connectCustomerServiceWithUserId.js';
import updateLiveStreamPreset from '../action/updateLiveStreamPreset.js';

import getAssetData from '../selector/getAssetData.js';
import getMessageData from '../selector/getMessageData.js';

import {
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
} from '../resource/configPriority.js';

import {
  STREAM_ONLINE,
  STREAM_OFFLINE,
  STREAM_VIEWERS_UPDATED,
  GOAL_STARTED,
  GOAL_ADDED,
  GOAL_ENDED,
  GOAL_PROGRESS_UPDATED,
  STREAM_AUTHORIZED,
  COUNTER_ADDED,
  DEVICE_ONLINE,
  DEVICE_OFFLINE,
  GIFT_SENT,
  CHAT_USER_ENTERED,
  CHAT_MESSAGE_SENT,
  SIC_BO_CREATED,
  SIC_BO_ENDED,
} from '../serviceWorker/SWMessageTypes.js';
import { PRESET_PREVIEW, PRESET_SD } from '../resource/liveStreamConstants.js';
import { pusher as pusherDebug } from '../resource/debug.js';
import batchPusherEvents from '../resource/batchPusherEvents.js';

const pusherLog = pusherDebug.extend('log:handlePusherAuthenticated');

let handleMessageFromSW = null;

/**
 * Handle pusher authenticated
 * @kind action
 * @param {Object} arg
 * @param {string} arg.channel - the name of channel.
 * @param {string} arg.auth - auth string.
 * @param {string} arg.channel_data - data come from Backend Pusher Authenticate API
 * @param {string} arg.socketId - socket id which pusher using
 * @param {boolean} arg.isFromAuthenticated - whether the event is from channel authenticated.
 * @return {Promise} Action promise.
 */
const handlePusherAuthenticated =
  ({ channel, channel_data, socketId, isFromAuthenticated = true }) =>
  async (dispatch, getState) => {
    if (!handleMessageFromSW) {
      handleMessageFromSW = (await import('../serviceWorker/helpers.js'))
        .handleMessageFromSW;
    }

    const handleEvents = (events, override) => {
      const batchedEvents = batchPusherEvents({
        types: [
          CHAT_USER_ENTERED,
          CHAT_MESSAGE_SENT,
          GIFT_SENT,
          SIC_BO_CREATED,
          SIC_BO_ENDED,
        ],
        events: events,
      });
      batchedEvents?.forEach(({ event, data } = {}) => {
        handleMessageFromSW({
          type: event,
          payload: {
            ...data,
            ...(typeof override === 'function' ? override(event) : override),
          },
        });
      });
    };
    let channelData = {};

    if (channel_data) {
      channelData = JSON.parse(channel_data);
    }

    pusherLog(channel, channelData);

    if (channel && isFromAuthenticated) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['pusher', 'channels'],
          data: {
            [channel]: {
              authenticated: true,
            },
          },
        },
      });
    }

    // set mixpanel meta data. come from presence-enc-client & presence-enc-user
    const mixpanelMeta = channelData?.user_info?.mixpanel;
    if (typeof mixpanelMeta === 'object' && mixpanelMeta != null) {
      setRemoteMeta({ data: mixpanelMeta });
    }

    const regionGroup =
      channelData?.user_info?.metadata?.['region-group'] || null;
    if (regionGroup) {
      dispatch(
        mergeMeData({
          field: 'regionGroup',
          value: regionGroup,
        })
      );
    }

    const userCountry = channelData?.user_info?.metadata?.country;
    if (userCountry) {
      dispatch(
        mergeMeData({
          field: 'country',
          value: userCountry,
        })
      );
    }

    if (channel.startsWith('presence-enc-client@')) {
      const user_info = channelData?.user_info;
      const abTestToken = user_info?.ab;

      await dispatch(
        setAbTestConfig({
          abToken: abTestToken,
          priority: CONFIG_PRIORITY_PRESENCE_CLIENT,
        })
      );

      if (user_info['configuration-group-id']) {
        await dispatch(
          setConfigurations({
            configData: user_info,
            priority: CONFIG_PRIORITY_PRESENCE_CLIENT,
          })
        );
      }
    }

    // set ab test token and user balance. only from the channel presence-enc-user@{USER_ID}
    // set Remote config
    if (channel.startsWith('presence-enc-user@')) {
      const user_info = channelData?.user_info;
      const abTestToken = user_info?.ab;
      const events = channelData?.user_info?.events;
      const userId = /^presence-enc-user@([a-z\d]+)$/i.exec(channel)?.[1];

      await dispatch(
        setAbTestConfig({
          abToken: abTestToken,
          priority: CONFIG_PRIORITY_PRESENCE_USER,
        })
      );
      if (user_info['configuration-group-id']) {
        await dispatch(
          setConfigurations({
            configData: user_info,
            priority: CONFIG_PRIORITY_PRESENCE_USER,
          })
        );
      }
      dispatch(
        connectCustomerServiceWithUserId({
          intercomUserHash: user_info?.intercom?.user_hash,
          zendeskUserJwt: user_info?.zendesk?.user_jwt,
        })
      );

      handleEvents(events, { userId });
    }

    if (channel.startsWith('private-enc-goal@')) {
      const privateGoalExecResult = /^private-enc-goal@([a-z\d]+)$/i.exec(
        channel
      );
      const goalId = privateGoalExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { goalId });
    }
    if (channel.startsWith('presence-enc-goal@')) {
      const precenseGoalExecResult =
        /^presence-enc-goal@([a-z\d]+)\.([a-z\d]+)$/i.exec(channel);
      const goalId = precenseGoalExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { goalId });
    }

    // private-enc-user@USER_ID
    if (channel.startsWith('private-enc-user@')) {
      const userId = channel.replace('private-enc-user@', '');
      const events = channelData?.user_info?.events;
      handleEvents(events, event =>
        [
          COUNTER_ADDED,
          STREAM_ONLINE,
          STREAM_OFFLINE,
          STREAM_VIEWERS_UPDATED,
          GOAL_STARTED,
          GOAL_ADDED,
          GOAL_ENDED,
          GOAL_PROGRESS_UPDATED,
          DEVICE_ONLINE,
          DEVICE_OFFLINE,
        ].includes(event)
          ? { userId, streamId: userId }
          : { userId }
      );
    }

    // private-enc-stream@STREAMER_ID
    const privateStreamExecResult = /^private-enc-stream@([a-z\d]+)$/i.exec(
      channel
    );

    if (privateStreamExecResult != null) {
      const streamId = privateStreamExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { streamId });
    }

    // presence-enc-stream-viewer@STREAMER_ID.PRESET.VIEWER_ID
    const presenceStreamViewerExecResult =
      /^presence-enc-stream-viewer@([a-z\d]+)\.([a-z]+)\.([a-z\d]+)$/i.exec(
        channel
      );

    if (presenceStreamViewerExecResult != null) {
      const streamId = presenceStreamViewerExecResult[1];
      const preset = presenceStreamViewerExecResult[2];
      const userId = presenceStreamViewerExecResult[3];
      const events = channelData?.user_info?.events;
      if (preset === PRESET_SD) {
        if (
          !events?.length ||
          !events.map(event => event.event).includes(STREAM_AUTHORIZED)
        ) {
          // No token in private channel, switch to preview channel
          dispatch(
            updateLiveStreamPreset({
              streamId,
              preset: PRESET_PREVIEW,
              pusherPreset: PRESET_PREVIEW,
            })
          );
        }
      }
      handleEvents(events, { streamId, preset, userId });
    }

    // presence-enc-message@MESSAGE_ID
    const presenceMessageExecResult = /^presence-enc-message@([a-z\d]+)$/i.exec(
      channel
    );
    if (presenceMessageExecResult != null) {
      const messageId = presenceMessageExecResult[1];
      const uploadJobId = getMessageData(getState(), messageId, 'uploadJobId');
      const events = channelData?.user_info?.events;
      handleEvents(events, { messageId, uploadJobId });
    }

    // presence-enc-asset@ASSET_ID
    const presenceAssetExecResult = /^presence-enc-asset@([a-z\d]+)$/i.exec(
      channel
    );
    if (presenceAssetExecResult != null) {
      const assetId = presenceAssetExecResult[1];
      const uploadJobId = getAssetData(getState(), [assetId], 'uploadJobId');
      const events = channelData?.user_info?.events;
      handleEvents(events, { assetId, uploadJobId });
    }

    // presence-enc-order@ORDER_ID
    const presenceOrderExecResult = /^presence-enc-order@([a-z\d]+)$/i.exec(
      channel
    );
    if (presenceOrderExecResult != null) {
      const orderId = presenceOrderExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { orderId });
    }

    // presence-enc-stream-exclusive@STREAM_ID
    const presenceStreamExclusiveExecResult =
      /^presence-enc-stream-exclusive@([a-z\d]+)$/i.exec(channel);
    if (presenceStreamExclusiveExecResult != null) {
      const streamId = presenceStreamExclusiveExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { streamId });
    }

    // private-enc-campaign@CAMPAIGN_NAME
    const privateCampaignExecResult =
      /^private-enc-campaign@([a-z\d_]+)$/i.exec(channel);
    if (privateCampaignExecResult != null) {
      const campaignName = privateCampaignExecResult[1];
      const events = channelData?.user_info?.events;
      handleEvents(events, { campaignName });
    }

    if (socketId) {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['pusher'],
          data: {
            socketId,
          },
        },
      });
    }
  };

export default handlePusherAuthenticated;
