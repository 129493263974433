// fetchRetainedEvents.js
'use strict';

import fetch from '../resource/customFetch.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';

import getNetworkingData from '../selector/getNetworkingData.js';

import handlePusherAuthenticated from '../action/handlePusherAuthenticated.js';

import {
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_FETCHING_BATCH,
  SET_NETWORKING_SUCCESS_BATCH,
  SET_NETWORKING_ERROR_BATCH,
} from '../ActionTypes.js';

/**
 * Fetch retained events from the specified channels.
 * @kind action
 * @param {array} {channels = []} - An array of channel names to fetch retained events from.
 * @return {Promise} Action promise.
 */
const fetchRetainedEvents =
  ({ channels = [] }) =>
  async (dispatch, getState) => {
    if (!channels.length) return dispatch({ type: '' });
    const networkingSelectPath = ['fetch', 'retainedEvent'];
    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
      },
    };
    const fetchUrl = getResourceUrl({ endpoint: '/pusher/retained-events' });
    [...channels].sort().forEach(channel => {
      fetchUrl.searchParams.append('channels', channel);
    });
    const stateBeforeFetching = getState();
    dispatch({
      type: SET_NETWORKING_FETCHING_BATCH,
      payload: { selectPath: networkingSelectPath, items: channels },
    });
    try {
      let response = await fetch(fetchUrl.href, fetchOptions);
      if (!response.ok) {
        await handleFetchError({ response });
      }
      const payload = (await response.json()) || {};
      const handleEvents = () => {
        Object.entries(payload).forEach(([channel, events]) =>
          dispatch(
            handlePusherAuthenticated({
              isFromAuthenticated: false,
              channel,
              channel_data: JSON.stringify({
                user_info: {
                  events,
                },
              }),
            })
          )
        );
      };
      if (channels.length > 1) {
        handleEvents();
        return dispatch({
          type: SET_NETWORKING_SUCCESS_BATCH,
          payload: { selectPath: networkingSelectPath, items: channels },
        });
      }
      const channel = channels[0];
      const lastEtag = getNetworkingData(
        stateBeforeFetching,
        [...networkingSelectPath, channel],
        'etag'
      );
      const etag = response.headers.get('etag');
      if (etag && etag !== lastEtag) {
        handleEvents();
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: [...networkingSelectPath, channel], etag },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR_BATCH,
        payload: { selectPath: networkingSelectPath, error, items: channels },
      });
    }
  };

export default fetchRetainedEvents;
