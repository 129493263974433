// updateLiveStreamPreset.js
'use strict';
import {
  REMOVE_OPERATION_DATA,
  MERGE_LIVE_STREAM_DATA,
  MERGE_OPERATION_DATA,
} from '../ActionTypes.js';
import { PRESET_PREVIEW, PRESET_SD } from '../resource/liveStreamConstants.js';

/**
 * Update live stream preset
 * @kind action
 * @param {'sd'|'preview'} {preset} - stream preset.
 * @param {'sd'|'preview'} {pusherPreset} - puser subscribeing  preset.
 * @param {string} {streamId} - live stream id.
 * @param {string} {streamToken} - live stream token.
 * @param {object} {decodedToken} - decoded stream token.
 * @param {string} {byteplusToken} - byteplus rtc room token.
 * @param {number} {byteplusTokenNbf} - byteplus rtc room token nbf.
 * @param {number} {byteplusTokenExp} - byteplus rtc room token exp.
 * @return {Promise} Action promise.
 */
const updateLiveStreamPreset =
  ({
    preset,
    pusherPreset,
    streamId,
    streamToken,
    decodedToken,
    byteplusToken,
    byteplusTokenNbf,
    byteplusTokenExp,
  }) =>
  async dispatch => {
    if (preset === PRESET_PREVIEW) {
      dispatch({
        type: REMOVE_OPERATION_DATA,
        payload: {
          selectPath: ['liveStream', 'paid', streamId],
        },
      });
    }
    if (preset === PRESET_SD && streamToken) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['liveStream', 'paid', streamId],
          data: {
            streamToken,
            decodedToken,
            byteplusToken,
            byteplusTokenNbf,
            byteplusTokenExp,
          },
        },
      });
    }
    return dispatch({
      type: MERGE_LIVE_STREAM_DATA,
      payload: {
        selectPath: [streamId],
        data: { preset, pusherPreset },
      },
    });
  };

export default updateLiveStreamPreset;
