// connectCustomerServiceWithUserId.js
'use strict';
import getMeData from '../selector/getMeData.js';
import mergeMeData from '../action/mergeMeData.js';
import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';
import { push as customerServicePush } from '../resource/customerService.js';

/**
 * Connect Intercom With User Id.
 * @kind action
 * @param {string} {intercomUserHash} - user_hash from pusher event data.
 * @param {string} {zendeskUserJwt} - user_hash from pusher event data.
 * @return {Promise} Action promise.
 */
const connectCustomerServiceWithUserId =
  ({ intercomUserHash, zendeskUserJwt }) =>
  async (dispatch, getState) => {
    const userId = getMeData(getState(), 'id');

    if ((!intercomUserHash && !zendeskUserJwt) || !userId) {
      return { type: '' };
    }

    const username = getMeData(getState(), 'username');
    const userDisplayName = getMeData(getState(), 'displayName');
    const avatar = {
      type: 'avatar',
      image_url: getUserPicture({
        userId,
        meId: userId,
        size: 128,
        format: MediaAssetFormat.JPG,
      }).href,
    };
    customerServicePush({
      args: [
        'loginUser',
        {
          intercomUserHash,
          zendeskUserJwt,
          userId,
          username,
          userDisplayName,
          avatar,
        },
      ],
    });

    dispatch(
      mergeMeData({
        field: 'zendeskUserJwt',
        value: zendeskUserJwt,
      })
    );
    return dispatch(
      mergeMeData({
        field: 'intercomUserHash',
        value: intercomUserHash,
      })
    );
  };

export default connectCustomerServiceWithUserId;
